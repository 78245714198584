<template>
  <page-setup>
    <v-container fluid>
      <v-row dense>
        <v-col>
          <div class="text-h5 text-color-main font-weight-black my-4">
            {{ title }}
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <devotional-component />
        </v-col>
      </v-row>
    </v-container>
  </page-setup>
</template>

<script>
const DevotionalComponent = () =>
  import("../components/DevotionalComponent.vue");

export default {
  components: {
    DevotionalComponent
  },
  data: () => ({
    title: "Devotionals"
  })
};
</script>
